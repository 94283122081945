import React from "react"
import "../css/style.css"

export default function Maps() {
  return (
    <section className="maps-section">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.567013812863!2d-99.01436908578233!3d19.38789214713422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1e2d4b8030e93%3A0x529bd1dfc57c11d2!2sCalle%20Santa%20Monica%20214%2C%20Gral%20Vicente%20Villada%2C%2057710%20Nezahualc%C3%B3yotl%2C%20M%C3%A9x.!5e0!3m2!1ses-419!2smx!4v1611391753254!5m2!1ses-419!2smx"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        className="maps-iframe"
        title="google-maps"
      ></iframe>
    </section>
  )
}
