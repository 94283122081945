import React from "react"
import "../css/style.css"

export default function About() {
  return (
    <section className="about-section">
      <div className="contact-spacing" id="nosotros"></div>
      <div className="about-grid">
        <div className="about-banner" />
        <div>
          <p className="about-title">NOSOTROS</p>
          <p className="about-text">
            Nuestra Empresa está orientada en la provisión de servicios de
            excelencia y calidad que corresponden a las necesidades actuales,
            con el mejor capital humano disponible en el mercado.
          </p>
          <p className="about-text">
            Involucramos práctica y experiencia constante, logrando así un
            profesionalismo más identificado con nuestra empresa, nuestros
            clientes y en permanente desarrollo individual y personal.
          </p>
          <p className="about-text about-bottom">
            Dedicada actualmente a la ejecución de Proyectos y Obra Eléctrica,
            Hidráulica Sanitaria, Sistemas Especiales en todas sus ramas,
            Calefacción y Ventilación, incluyendo en Edificios Habitacionales,
            Oficinas, Hotelería, Centros Comerciales, Plantas Industriales etc.
          </p>
        </div>
      </div>
    </section>
  )
}
