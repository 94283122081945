import React from "react"
import "../css/style.css"
import location from "../images/location.png"
import phone from "../images/phone.png"
import wa from "../images/smartphone.png"
import mail from "../images/mail.png"
import site from "../images/international.png"

export default function Info() {
  return (
    <section className="info-section">
      <div className="info-line" />
      <p className="info-title">Información de Contacto</p>
      <h4 className="info-name">RBL Ingenieria</h4>
      <div className="info-div">
        <div className="info-grid">
          <img className="info-icon" src={location} alt="ubicacion rbl" />
          <p className="info-text">
            Santa Mónica 214, General Vicente Villada 57710 Nezahualcóyotl, Méx.
          </p>
        </div>
      </div>
      <div className="info-div">
        <div className="info-grid">
          <img className="info-icon" src={phone} alt="telefono rbl" />
          <p className="info-text">(55) 75-95-2260</p>
        </div>
      </div>
      <div className="info-div">
        <div className="info-grid">
          <img className="info-icon" src={wa} alt="movil rbl" />
          <p className="info-text">(55) 85-06-1703</p>
        </div>
      </div>
      <div className="info-div">
        <div className="info-grid">
          <img className="info-icon" src={mail} alt="correo rbl" />
          <p className="info-text">info@rblingenieria.com.mx</p>
        </div>
      </div>{" "}
      <div className="info-div">
        <div className="info-grid">
          <img className="info-icon" src={site} alt="sitio web rbl" />
          <p className="info-text">rblingenieria.com.mx</p>
        </div>
      </div>
    </section>
  )
}
