import React from "react"
import "../css/style.css"

export default function Form() {
  return (
    <section className="form-section">
      <div>
        <div className="form-line" />
        <p className="form-title">Consulta Nuestros Servicios</p>
      </div>
      <div className="contact-form">
        <form
          method="post"
          data-netlify-honeypot="bot-field"
          data-netlify="true"
          name="contact"
        >
          <input
            className="form-input"
            name="Nombre:"
            placeholder="Nombre"
            type="text"
          />
          <input
            className="form-input"
            name="Correo:"
            placeholder="Email"
            type="email"
          />
          <input
            className="form-input"
            name="Telefono:"
            placeholder="Telefono"
            type="number"
          />
          <textarea
            className="form-textarea"
            name="Mensaje:"
            cols="auto"
            rows="10"
            placeholder="Consulta..."
          ></textarea>
          <input type="submit" className="form-button" value="Enviar Mensaje" />
        </form>
      </div>
    </section>
  )
}
