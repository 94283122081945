import React from "react"
import "../css/style.css"
import SwiperCore, { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import inel from "../images/electrico.jpg"
import inhi from "../images/hidraulica.jpg"
import sies from "../images/cctv.jpg"
import hvac from "../images/hvac.jpg"
import uvie from "../images/UVIE.jpg"

SwiperCore.use([Autoplay])

var params = {
  breakpoints: {
    240: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    500: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    1400: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
  },
  loop: true,
  spaceBetween: 0,
  autoplay: {
    delay: 7000,
    disableOnInteraction: false,
  },
  grabCursor: true,
}

export default function Services() {
  return (
    <section className="services-section">
      <div className="services-spacing" id="servicios"></div>
      <p className="services-title">Servicios</p>
      <p className="services-text">
        Nuestros servicios van mucho más allá de una administración básica de
        personal para el desarrollo de los trabajos de obras de instalaciones,
        hemos confirmado en nuestros años de experiencia, que el siguiente paso
        a una tercerización de personal altamente eficiente en un servicio con
        altos niveles de especialización, permite a los clientes obtener un
        valor agregado mucho mayor, que se traduce en una ventaja competitiva en
        el mercado.
      </p>
      <div className="services-carrousel">
        <Swiper {...params}>
          <SwiperSlide>
            <div className="services-box">
              <img
                className="services-image"
                src={inel}
                alt="Instalacion Electrica RBL"
              />
              <p className="services-title-box">Instalacion Electrica</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="services-box">
              <img
                className="services-image"
                src={inhi}
                alt="Instalacion Hidraulica RBL"
              />
              <p className="services-title-box">Instalacion Hidráulica</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="services-box">
              <img
                className="services-image"
                src={sies}
                alt="Sistemas Especiales RBL"
              />
              <p className="services-title-box">Sistemas Especiales</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="services-box">
              <img className="services-image" src={hvac} alt="HVAC RBL" />
              <p className="services-title-box">HVAC</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="services-box">
              <img className="services-image" src={uvie} alt="Gestorías RBL" />
              <p className="services-title-box">Gestorías</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}
