import React from "react"
import "../css/style.css"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"

SwiperCore.use([Autoplay])

var params = {
  spaceBetween: 0,
  loop: true,
  slidesPerView: 1,
  autoplay: {
    delay: 8000,
    disableOnInteraction: false,
  },
  speed: 2000,
  grabCursor: true,
}

export default function Projects() {
  return (
    <section className="projects-section">
      <div className="projects-spacing" id="proyectos"></div>
      <p className="projects-title">Proyectos</p>
      <div>
        <Swiper {...params} className="projects-carrousel">
          <SwiperSlide>
            <div className="projects-background-1">
              <p className="projects-title-box">
                Conjunto Residencial Malecón las Américas 2ª. Etapa
                <br />
                para Park Desarrolladora
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="projects-background-2">
              <p className="projects-title-box">
                Plaza Comercial Urban Juriquilla
                <br />
                para Central de Arquitectura.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="projects-background-3">
              <p className="projects-title-box">
                UNITEC Campus Sur
                <br />
                Para Eichelman Arquitectos
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}
