import React from "react"
import "../css/style.css"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, EffectFade } from "swiper"

SwiperCore.use([Autoplay, EffectFade])

var params = {
  spaceBetween: 0,
  loop: true,
  slidesPerView: 1,
  effect: { crossFade: true },
  effect: "fade",
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  speed: 2000,
  centeredSlides: true,
  grabCursor: false,
}

export default function Main() {
  return (
    <section className="main-section upcase">
      <div className="main-background"></div>
      <Swiper {...params}>
        <SwiperSlide>
          <div className="main-background-1"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main-background-2"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main-background-1"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main-background-2"></div>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}
