import React from "react"
import SEO from "../components/seo"
import Main from "../components/main"
import Services from "../components/services"
import "../css/style.css"
import Contact from "../components/contact"
import About from "../components/about"
import Mision from "../components/mision"
import Projects from "../components/proyects"

const IndexPage = () => (
  <>
    <div className="upcase">
      <SEO title="Inicio" />
      <Main />
      <About />
      <Mision />
      <Services />
      <Projects />
      <Contact />
    </div>
  </>
)

export default IndexPage
