import React from "react"
import "../css/style.css"
import mision from "../images/mision.jpg"
import filosofia from "../images/filosofia.jpg"
import valores from "../images/valores.jpg"

export default function Mision() {
  return (
    <section className="mision-section">
      <div className="mision-grid">
        <div className="mision-box-1">
          <div className="mision-image-sec">
            <img className="mision-image" src={mision} alt="RBL Mision" />
          </div>
          <p className="mision-title">Misión</p>
          <p className="mision-text">
            Nacimos para brindar un servicio integral, ético, de calidad,
            transparente, responsable y competente con el mejor capital humano y
            las herramientas tecnológicas y profesionales necesarias para lograr
            que nuestros clientes sean más competitivos.
          </p>
        </div>
        <div className="mision-box-2">
          <div className="mision-image-sec">
            <img className="mision-image" src={filosofia} alt="RBL filosofia" />
          </div>
          <p className="mision-title">Filosofía</p>
          <p className="mision-text">
            Nos involucrarnos con nuestros clientes desde el primer contacto, de
            manera abierta, proactiva y profunda permitiendo identificar y
            responder estratégicamente a cuales quiera que sean sus
            oportunidades de negocio en tiempo y forma.
          </p>
        </div>
        <div className="mision-box-3">
          <div className="mision-image-sec">
            <img className="mision-image" src={valores} alt="RBL valores" />
          </div>
          <p className="mision-title">Valores</p>
          <div className="mision-grid-valores">
            <div>
              <p className="mision-text-space">
                <li>Respeto</li>
                <li> Ética</li>
                <li> Transparencia</li>
                <li> Integridad</li>
              </p>
            </div>
            <div>
              <p className="mision-text-space">
                <li>Calidad</li>
                <li> Responsabilidad</li>
                <li> Gestión Eficiente</li>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
