import React from "react"
import "../css/style.css"
import Form from "./form"
import Info from "./info"
import Maps from "./maps"

export default function Contact() {
  return (
    <section className="contact-section">
      <div className="contact-spacing" id="contacto"></div>
      <div className="contact-content">
        <div className="contact-grid">
          <Form />
          <Info />
        </div>
        <Maps />
      </div>
    </section>
  )
}
